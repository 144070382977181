<template>
  <div class="report-form">
    <form @submit.prevent="onFormSubmit">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("report-form.title-field", "capitalize") }}</h3>
        </div>
        <vs-input
          :label-placeholder="
            $l.get('report-form.title-field', 'capitalize') + ' (en-US)'
          "
          :danger="isTitleEnUsInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="report.titleEnUs"
        />
        <vs-input
          :label-placeholder="
            $l.get('report-form.title-field', 'capitalize') + ' (pt-BR)'
          "
          :danger="isTitlePtBrInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="report.titlePtBr"
        />
        <vs-input
          :label-placeholder="
            $l.get('report-form.title-field', 'capitalize') + ' (es-ES)'
          "
          :danger="isTitleEsEsInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="report.titleEsEs"
        />
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("report-form.subtitle-field", "capitalize") }}</h3>
        </div>
        <vs-input
          :label-placeholder="
            $l.get('report-form.subtitle-field', 'capitalize') + ' (en-US)'
          "
          :danger="isSubtitleEnUsInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="report.subtitleEnUs"
        />
        <vs-input
          :label-placeholder="
            $l.get('report-form.subtitle-field', 'capitalize') + ' (pt-BR)'
          "
          :danger="isSubtitlePtBrInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="report.subtitlePtBr"
        />
        <vs-input
          :label-placeholder="
            $l.get('report-form.subtitle-field', 'capitalize') + ' (es-ES)'
          "
          :danger="isSubtitleEsEsInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="report.subtitleEsEs"
        />
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("report-form.permission-field", "capitalize") }}</h3>
        </div>
        <ul class="centerx">
          <li>
            <vs-radio
              color="success"
              v-model="report.permissionType"
              vs-name="permissionType"
              vs-value="1"
              >{{ $l.get("dictionary.permission-types.global") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              color="warning"
              v-model="report.permissionType"
              vs-name="permissionType"
              vs-value="2"
              >{{ $l.get("dictionary.permission-types.plans") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              color="danger"
              v-model="report.permissionType"
              vs-name="permissionType"
              vs-value="3"
              >{{ $l.get("dictionary.permission-types.admins") }}</vs-radio
            >
          </li>
        </ul>
        <div v-if="report.permissionType == 2 && !!plans">
          <ul class="centerx" style="margin-top: 10px">
            <li v-for="(plan, idx) in plans" :key="idx">
              <vs-checkbox v-model="plan.isSelected">{{
                plan.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.precision", "capitalize") }}</h3>
        </div>
        <ul class="centerx">
          <li>
            <vs-radio
              color="primary"
              v-model="report.reliability"
              vs-name="reliability"
              vs-value="0"
            >
              <feather-icon icon="StarIcon" svgClasses="text-warning h-4 w-4" />
            </vs-radio>
          </li>
          <li>
            <vs-radio
              color="primary"
              v-model="report.reliability"
              vs-name="reliability"
              vs-value="25"
            >
              <feather-icon
                icon="StarIcon"
                svgClasses="fill-current text-warning h-4 w-4"
              />
            </vs-radio>
          </li>
          <li>
            <vs-radio
              color="primary"
              v-model="report.reliability"
              vs-name="reliability"
              vs-value="75"
            >
              <feather-icon
                icon="StarIcon"
                svgClasses="fill-current text-warning h-4 w-4"
              />
              <feather-icon
                icon="StarIcon"
                svgClasses="fill-current text-warning h-4 w-4"
              />
            </vs-radio>
          </li>
          <li>
            <vs-radio
              color="primary"
              v-model="report.reliability"
              vs-name="reliability"
              vs-value="100"
            >
              <feather-icon
                icon="StarIcon"
                svgClasses="fill-current text-warning h-4 w-4"
              />
              <feather-icon
                icon="StarIcon"
                svgClasses="fill-current text-warning h-4 w-4"
              />
              <feather-icon
                icon="StarIcon"
                svgClasses="fill-current text-warning h-4 w-4"
              />
            </vs-radio>
          </li>
        </ul>
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("report-form.result-field", "capitalize") }}</h3>
        </div>
        <vs-input
          :label-placeholder="
            $l.get('report-form.result-field', 'capitalize') + ' (en-US)'
          "
          :danger="isResultEnUsInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="report.resultEnUs"
        />
        <vs-input
          :label-placeholder="
            $l.get('report-form.result-field', 'capitalize') + ' (pt-BR)'
          "
          :danger="isResultPtBrInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="report.resultPtBr"
        />
        <vs-input
          :label-placeholder="
            $l.get('report-form.result-field', 'capitalize') + ' (es-ES)'
          "
          :danger="isResultEsEsInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="report.resultEsEs"
        />
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("report-form.data-source-field", "capitalize") }}</h3>
        </div>
        <ul class="centerx">
          <li>
            <vs-radio
              v-model="report.dataSourceType"
              vs-name="dataSourceType"
              vs-value="1"
              >{{ $l.get("dictionary.data-source-type.dna-skills") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="report.dataSourceType"
              vs-name="dataSourceType"
              vs-value="2"
              >{{ $l.get("dictionary.data-source-type.researchers") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="report.dataSourceType"
              vs-name="dataSourceType"
              vs-value="3"
              >{{ $l.get("dictionary.data-source-type.databases") }}</vs-radio
            >
          </li>
        </ul>
        <ul class="centerx">
          <li>
            <vs-radio
              v-model="report.dataSourceVisibility"
              vs-name="dataSourceVisibility"
              vs-value="1"
              >{{
                $l.get("dictionary.data-source-visibility.public")
              }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="report.dataSourceVisibility"
              vs-name="dataSourceVisibility"
              vs-value="2"
              >{{
                $l.get("dictionary.data-source-visibility.private")
              }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="report.dataSourceVisibility"
              vs-name="dataSourceVisibility"
              vs-value="3"
              >{{
                $l.get("dictionary.data-source-visibility.admins")
              }}</vs-radio
            >
          </li>
        </ul>
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("report-form.description-field", "capitalize") }}</h3>
        </div>
        <vs-tabs>
          <vs-tab label="en-US">
            <div>
              <VueEditor
                :editorOptions="editorSettings"
                v-model="report.descriptionHtmlEnUs"
              ></VueEditor>
            </div>
          </vs-tab>
          <vs-tab label="pt-BR">
            <div>
              <VueEditor
                :editorOptions="editorSettings"
                v-model="report.descriptionHtmlPtBr"
              ></VueEditor>
            </div>
          </vs-tab>
          <vs-tab label="es-ES">
            <div>
              <VueEditor
                :editorOptions="editorSettings"
                v-model="report.descriptionHtmlEsEs"
              ></VueEditor>
            </div>
          </vs-tab>
        </vs-tabs>
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("report-form.variants-field", "capitalize") }}</h3>
        </div>
        <vs-tabs v-model="variantTabIndex">
          <vs-tab :label="$l.get('dictionary.all')">
            <vs-table :data="report.variants">
              <template slot="thead">
                <vs-th>{{ $l.get("dictionary.origin") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.edit") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.remove") }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="idx" v-for="(tr, idx) in data">
                  <vs-td :data="tr.genosetValue" v-if="tr.genosetValue">
                    {{ tr.genosetValue }}
                  </vs-td>
                  <vs-td :data="tr.genotypeValue" v-if="tr.genotypeValue">{{
                    tr.genotypeValue
                  }}</vs-td>
                  <vs-td>
                    <vs-button
                      color="primary"
                      type="border"
                      icon="edit"
                      @click="onEditVariantClick(idx, tr)"
                    ></vs-button>
                  </vs-td>
                  <vs-td>
                    <vs-button
                      color="primary"
                      type="border"
                      icon="delete"
                      @click="onRemoveVariantClick(idx)"
                    ></vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>
          <vs-tab :label="variantFormTab">
            <ul class="centerx">
              <li>
                <vs-radio
                  v-model="variant.originType"
                  vs-name="variantOriginType"
                  vs-value="1"
                  >{{ $l.get("dictionary.genoset") }}</vs-radio
                >
              </li>
              <li>
                <vs-radio
                  v-model="variant.originType"
                  vs-name="variantOriginType"
                  vs-value="2"
                  >{{ $l.get("dictionary.genotype") }}</vs-radio
                >
              </li>
            </ul>
            <div style="margin-top: -25px"></div>
            <GenosetInput
              :errors="genosetErrors"
              :initial="variant.genosetValue"
              :onChange="onGenosetChange"
              v-if="variant.originType == 1"
            />
            <GenotypeInput
              :errors="genotypeErrors"
              :initial="variant.genotypeValue"
              :onChange="onGenotypeChange"
              v-if="variant.originType == 2"
            />
            <br />
            <div v-if="variant.figure" style="text-align: center;">
              <img :src="variant.figure.figure" style="height: 128px" />
              <vs-button
                color="danger"
                type="border"
                icon-pack="feather"
                icon="icon-trash"
                style="margin: auto; width: 150px !important"
                @click="onVariantRemoveFigureClick()"
              ></vs-button>
            </div>
            <div v-else>
              <vs-upload
                :action="uploadAddress"
                :headers="uploadHeaders"
                fileName="figure"
                :limit="1"
                :show-upload-button="true"
                :automatic="true"
                accept=".jpg, .png"
                :text="$l.get('behaviour-form.input-label-figure', 'upper')"
                @on-success="onUploadSuccess"
                @on-fail="onUploadFail"
              />
            </div>
            <br />
            <vs-tabs>
              <vs-tab
                :label="
                  $l.get('report-form.result-field', 'capitalize') + ' (en-US)'
                "
              >
                <vs-input
                  :label-placeholder="
                    $l.get('report-form.result-field', 'capitalize') +
                      ' (en-US)'
                  "
                  :danger-text="$l.get('errors.input-required', 'capitalize')"
                  v-model="variant.resultEnUs"
                />
              </vs-tab>
              <vs-tab
                :label="
                  $l.get('report-form.result-field', 'capitalize') + ' (pt-BR)'
                "
              >
                <vs-input
                  :label-placeholder="
                    $l.get('report-form.result-field', 'capitalize') +
                      ' (pt-BR)'
                  "
                  :danger-text="$l.get('errors.input-required', 'capitalize')"
                  v-model="variant.resultPtBr"
                />
              </vs-tab>
              <vs-tab
                :label="
                  $l.get('report-form.result-field', 'capitalize') + ' (es-ES)'
                "
              >
                <vs-input
                  :label-placeholder="
                    $l.get('report-form.result-field', 'capitalize') +
                      ' (es-ES)'
                  "
                  :danger-text="$l.get('errors.input-required', 'capitalize')"
                  v-model="variant.resultEsEs"
                />
              </vs-tab>
            </vs-tabs>
            <br />
            <vs-tabs>
              <vs-tab label="en-US">
                <div>
                  <VueEditor
                    :editorOptions="editorSettings"
                    v-model="variant.aboutEnUs"
                  ></VueEditor>
                </div>
              </vs-tab>
              <vs-tab label="pt-BR">
                <div>
                  <VueEditor
                    :editorOptions="editorSettings"
                    v-model="variant.aboutPtBr"
                  ></VueEditor>
                </div>
              </vs-tab>
              <vs-tab label="es-ES">
                <div>
                  <VueEditor
                    :editorOptions="editorSettings"
                    v-model="variant.aboutEsEs"
                  ></VueEditor>
                </div>
              </vs-tab>
            </vs-tabs>

            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-save"
              style="margin: auto; width: 150px !important"
              @click="onSaveVariantClick()"
            ></vs-button>
          </vs-tab>
          <vs-tab :label="$l.get('dictionary.edit')" v-if="variantTabIndex == 2"
            >edit!</vs-tab
          >
        </vs-tabs>
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("report-form.references-field", "capitalize") }}</h3>
        </div>
        <div>
          <vs-textarea
            :label="$l.get('report-form.references-field')"
            v-model="report.bibliographicReferencesHtml"
          />
        </div>
      </vs-card>
      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">{{
        $l.get("dictionary.submit-new")
      }}</vs-button>
    </div>
  </div>
</template>

<script>
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { VueEditor } from "vue2-editor";
import GenosetInput from "@/application/components/dna/GenosetInput";
import GenotypeInput from "@/application/components/dna/GenotypeInput";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import ImageCompress from "quill-image-compress";
import { htmlEditButton } from "quill-html-edit-button";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);
Quill.register("modules/htmlEditButton", htmlEditButton);

export default {
  name: "report-form",
  props: ["report", "plans", "onSubmit"],
  components: { VueEditor, GenosetInput, GenotypeInput },
  data() {
    return {
      // editor: ClassicEditor,
      isTitleEnUsInvalid: false,
      isTitlePtBrInvalid: false,
      isTitleEsEsInvalid: false,
      isSubtitleEnUsInvalid: false,
      isSubtitlePtBrInvalid: false,
      isSubtitleEsEsInvalid: false,
      isResultEnUsInvalid: false,
      isResultPtBrInvalid: false,
      isResultEsEsInvalid: false,
      variantTabIndex: 0,
      genosetErrors: [],
      genotypeErrors: [],
      variantEditIdx: null,
      variant: null,
      session: null,
      figureGateway: null,
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
          imageCompress: {},
          htmlEditButton: {}
        }
      }
    };
  },
  beforeMount() {
    let sessionGateway = this.$injector.get("SessionGateway");
    this.session = sessionGateway.get();
    this.figureGateway = this.$injector.get("FigureGateway");
    this.clearVariant();
  },
  mounted() {},
  watch: {
    variantTabIndex: function() {
      if (this.variantTabIndex == 0) {
        this.variantEditIdx = null;
        this.clearVariant();
      }
    }
  },
  computed: {
    variantFormTab() {
      if (this.variantEditIdx == null) {
        return this.$l.get("dictionary.new");
      } else {
        return this.$l.get("dictionary.edit");
      }
    },
    uploadAddress() {
      return `${this.figureGateway.URL}/`;
    },
    uploadHeaders() {
      return this.session.tokens.getHeader();
    }
  },
  methods: {
    onFormSubmit() {
      this.onSubmit();
    },
    clearVariant() {
      this.variant = {
        genotypeValue: "",
        genotype: null,
        genosetValue: "",
        genoset: null,
        originType: 1,
        aboutEnUs: "",
        aboutPtBr: "",
        aboutEsEs: ""
      };
    },
    onGenosetChange(genoset, value) {
      this.variant.genosetValue = value;
      this.variant.genoset = genoset;
    },
    onGenotypeChange(snpId, alleles, value, genotypes) {
      this.isValid =
        value.length < 1 || (genotypes != null && genotypes.length > 0);
      if (!this.isValid) {
        this.genotypeErrors.push(this.$l.get("errors.genotype-not-found"));
      } else if (genotypes != null) {
        this.variant.genotypeValue = value;
        this.variant.genotype = genotypes[0].id;
      }
    },
    onEditVariantClick(idx, variant) {
      this.variantEditIdx = idx;
      this.variant = JSON.parse(JSON.stringify(variant));
      this.variantTabIndex = 1;
    },
    onRemoveVariantClick(idx) {
      this.report.variants.splice(idx, 1);
    },
    onSaveVariantClick() {
      if (this.variant.originType == 1) {
        if (this.variant.genoset == null) {
          this.genosetErrors.push(this.$l.get("errors.input-required"));
          return;
        }
        this.variant.genotypeValue = "";
        this.variant.genotype = null;
      } else if (this.variant.originType == 2) {
        if (this.variant.genotype == null) {
          this.genotypeErrors.push(this.$l.get("errors.input-required"));
          return;
        }
        this.variant.genosetValue = "";
        this.variant.genoset = null;
      }
      if (this.variantEditIdx == null) {
        this.report.variants.push(this.variant);
      } else {
        this.report.variants[this.variantEditIdx] = this.variant;
      }
      this.clearVariant();
      this.variantTabIndex = 0;
    },
    onUploadSuccess(data) {
      let figure = JSON.parse(data.target.responseText);
      this.variant.figure = figure;
      this.$forceUpdate();
      //   this.interactors.uploadRawDataSuccess.handle(rawData);
    },
    onUploadFail(data) {
      return;
    },
    onVariantRemoveFigureClick() {
      this.variant.figure = null;
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
@import "~vue2-editor/dist/vue2-editor.css";
.report-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.report-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.report-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.report-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.report-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

.report-form .con-img-upload .con-input-upload {
  width: 100% !important;
  margin: 5px;
  // background: rgba(var(--vs-primary), 0.15) !important;
  box-shadow: 0px 5px 5px rgba(var(--vs-primary), 0.5);
}
.report-form .con-img-upload .con-input-upload:hover {
  box-shadow: 0px 10px 5px rgba(var(--vs-primary), 0.5);
}
.report-form .con-img-upload .con-input-upload:active {
  box-shadow: 0px 1px 5px rgba(var(--vs-primary), 0.5);
}

.report-form .con-img-upload .text-input {
  font-size: 12px;
  margin-top: -24px !important;
}

.report-form .con-img-upload .img-upload {
  display: none;
}
.report-form .con-img-upload .on-progress-all-upload {
  opacity: 1 !important;
}
.report-form .con-img-upload .disabled-upload {
  height: 4px !important;
}
</style>

<template>
  <vs-input
    :label="$l.get('dictionary.genoset', 'capitalize')"
    placeholder="gs0000"
    :danger="isInvalid"
    :danger-text="errorMessage"
    :success="isSuccess"
    v-on:keyup="onInputUpdate"
    v-model="input"
  />
</template>

<script>
const RE_GS = /^[Gg][Ss]\d+$/i;
//
export default {
  name: "genoset-input",
  props: ["errors", "initial", "onChange"],
  data() {
    return {
      input: "",
      timer: null,
      session: null,
      gateway: null,
      exists: false
    };
  },
  beforeMount() {
    var sessionGateway = this.$injector.get("SessionGateway");
    this.session = sessionGateway.get();
    this.gateway = this.$injector.get("GenosetGateway");
  },
  mounted() {
    this.input = this.initial;
  },
  computed: {
    isInvalid() {
      return this.errors && this.errors.length > 0;
    },
    isSuccess() {
      return this.exists && !this.isInvalid;
    },
    errorMessage() {
      if (this.isInvalid) {
        return this.errors[0];
      } else {
        return "";
      }
    }
  },
  methods: {
    onInputUpdate() {
      this.exists = false;
      this.onChange(null, "");
      if (this.timer) {
        clearTimeout(this.timer);
      }
      const _ = this;
      this.timer = setTimeout(async () => {
        _.input = _.input.trim();
        while (_.errors.length > 0) _.errors.pop();
        if (_.input.length == 0) return;
        if (!_.input.match(RE_GS)) {
          _.errors.push(_.$l.get("errors.invalid-genoset-name", "capitalize"));
        } else {
          const name = _.input;
          const genosets = await _.gateway.filter({ name }, _.session.tokens);
          if (genosets.length > 0) {
            _.exists = true;
            _.onChange(genosets[0].id, _.input);
          } else {
            _.errors.push(_.$l.get("errors.genoset-not-found", "capitalize"));
            _.onChange(null, _.input);
          }
        }
      }, 500);
    }
  }
};
</script>

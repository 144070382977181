<template>
  <vs-input
    :label="$l.get('genotype-form.genotype-field', 'capitalize')"
    placeholder="rs0000(A;A)"
    :danger="isInvalid"
    :danger-text="errorMessage"
    :success="isSuccess"
    v-on:keyup="onInputUpdate"
    v-model="input"
  />
</template>

<script>
const RE_GT = /^([Ii]|[Rr][Ss])\d+\([A-Z]+(;[A-Z]+)?\)$/i;
//
export default {
  name: "genotype-input",
  props: ["errors", "initial", "onChange"],
  data() {
    return {
      input: "",
      timer: null,
      session: null,
      snpGateway: null,
      genotypeGateway: null,
      exists: false
    };
  },
  beforeMount() {
    var sessionGateway = this.$injector.get("SessionGateway");
    this.session = sessionGateway.get();
    this.snpGateway = this.$injector.get("SnpGateway");
    this.genotypeGateway = this.$injector.get("GenotypeGateway");
  },
  mounted() {
    this.input = this.initial;
  },
  computed: {
    isInvalid() {
      return this.errors && this.errors.length > 0;
    },
    isSuccess() {
      return this.exists && !this.isInvalid;
    },
    errorMessage() {
      if (this.isInvalid) {
        return this.errors[0];
      } else {
        return "";
      }
    }
  },
  methods: {
    onInputUpdate() {
      this.exists = false;
      this.onChange(null, null, "", null);
      if (this.timer) {
        clearTimeout(this.timer);
      }
      const _ = this;
      this.timer = setTimeout(async () => {
        _.input = _.input.trim();
        while (_.errors.length > 0) _.errors.pop();
        if (_.input.length == 0) return;
        if (!_.input.match(RE_GT)) {
          _.errors.push(_.$l.get("errors.invalid-genotype", "capitalize"));
        } else {
          const data = _.input.replace(")", "").split("(");
          const rsid = data[0];
          const alleles = data[1];
          const snps = await _.snpGateway.filter({ rsid }, _.session.tokens);
          if (snps.length > 0) {
            const snp = snps[0].id;
            const genotypes = await _.genotypeGateway.filter(
              { snp, alleles },
              _.session.tokens
            );
            _.exists = true;
            _.onChange(snp, alleles, _.input, genotypes);
          } else {
            _.errors.push(_.$l.get("errors.snp-not-found", "capitalize"));
            _.onChange(null, null, _.input, null);
          }
        }
      }, 500);
    }
  }
};
</script>
